<template>
  <div class="column">
    <q-table flat wrap-cells
      :data="sujets"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="rowsPerPageOptions"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
      <q-tr slot="body" slot-scope="props" :props="props"
        @click.native="$router.push({ name: 'sujet-show', params: { id: props.row.id } })"
        class="cursor-pointer">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
          <div v-if="col.name === 'statut' && col.value" v-html="$t(`sujets.fields.statut.options.${col.value}`)" />
          <div v-else-if="col.value" v-html="col.value" />
        </q-td>
      </q-tr>
    </q-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SujetsList',

  data () {
    return {
      filter: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      sujets: 'sujets/getSujets',
      filters: 'sujets/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),

    pagination: {
      ...mapGetters({ get: 'sujets/getPagination' }),
      ...mapMutations({ set: 'sujets/setPagination' })
    },

    columns () {
      if (this.sujets.length === 0) return []
      else {
        const fields = ['date_instance', 'label', 'type_id', 'statut', 'date_limite']
        const sortable = []
        return fields.map(key => {
          let label = this.$t(`sujets.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'date_instance') {
            label = 'Instance'
            autoWidth = true
            formatFn = val => this.$formatDate(val)
          } else if (key === 'type_id') {
            formatFn = val => {
              if (!this.filters) return val
              const type_filter = this.filters.find(filter => filter.id === 'type_id')
              const option = type_filter.options.find(option => option.value === val)
              if (!option) return ''
              return this.$t(`sujets.fields.type_id.options.${option.label}`)
            }
          } else if (key === 'statut') {
          } else if (key === 'date_limite') {
            label = 'Limite d\'affectation'
            autoWidth = true
            formatFn = val => this.$formatDate(val)
            sortable.push(key)
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: sortable.indexOf(key) >= 0,
            autoWidth: autoWidth
          }
        })
      }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$nextTick(() => {
          this.onRequest({ pagination: this.pagination })
        })
      },
      deep: true
    }
  },

  mounted () {
    this.$store.commit('pages/setPageTitle', this.$t('sujets.sujets_list'))

    let params = (this.$route.name === 'sujets-a-planifier-list') ? { droits_title: 'A_PLANIFIER' } : {}
    this.$store.dispatch('sujets/fetchFilters', params)
    //
    // if (this.filters) { this.onRequest({ pagination: this.pagination }) }
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('sujets/setPagination', pagination)

      let params = { ...pagination, 'date_limite': pagination.descending ? 'DESC' : 'ASC' }
      this.filters.forEach(filter => { params[filter.id] = filter.value })
      if (this.$route.name === 'sujets-a-planifier-list') params.statut = 'A_PLANIFIER'

      this.loading = true
      this.$store.dispatch('sujets/fetchSujets', params)
        .finally(() => { this.loading = false })
    }
  }
}
</script>
